import styled from "styled-components";

const Figure = styled.div`
  display: grid;
  grid-template-rows: 5px 195px 50px;

  img {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    object-position: 50% 20%;
    width: 100%;
    height: 250px;
  }

  h2 {
    color: white;
    margin-top: 3px;
    margin-left: 2px;
    font-weight: 600;
    font-size: 1.8rem;
    text-shadow: 0 0 2px black;
  }
`;

const FigCaption = styled.figcaption`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  h3 {
    color: white;
    margin: 0 auto;
    align-self: center;
    text-align: center;
  }
`;

const månad = [
  "",
  "januari",
  "februari",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "augusti",
  "september",
  "oktober",
  "november",
  "december",
];

const Thumb = ({ img, evenemang, datum }) => {
  const [, m, d] = datum.split("-");
  const Namn = evenemang;

  return (
    <Figure>
      <img src={img} alt={img} />
      <h2>
        {parseInt(d, 10)} {månad[parseInt(m)]}
      </h2>
      <FigCaption>
        <h3>
          <Namn />
        </h3>
      </FigCaption>
    </Figure>
  );
};

export default Thumb;
