import styled from "styled-components";

import flygbild from "./../images/flygbild.jpg";
import rubrik_hem from "./../images/rubrik_hem.png";
import SpringSlides from "./SpringSlides";
import Ikoner from "./Ikoner";
import Vi from "./../components/Vi";

const HeaderStyle = styled.header`
  background: url(${flygbild}) no-repeat;
  background-size: cover;
  background-position: bottom center;
  display: grid;
  grid-template-rows: 50px 77px 70px;
  grid-template-columns: 1fr 0.5fr 0.5fr 1fr 1fr 3rem 2rem 2px;
  position: relative;
  opacity: 1;

  @media screen and (min-width: 600px) {
    grid-template-rows: 100px 77px 70px;
  }
  @media screen and (min-width: 800px) {
    grid-template-rows: 150px 77px 70px;
  }
`;

const SlideShowHolder = styled.div`
  height: 197px;
  width: 180px;
  overflow: hidden;

  justify-self: right;
  grid-column-start: -3;

  @media screen and (min-width: 400px) {
    grid-column-start: -4;
  }
  @media screen and (min-width: 600px) {
    grid-column-start: -5;
    margin-top: 25px;
  }
  @media screen and (min-width: 800px) {
    margin-top: 35px;
    height: 227px;
    width: 210px;
  }
`;

const SubMenu = styled.div`
  padding: 0.3rem 0.8rem 0 150px;
  display: grid;
  grid-template-columns: 1fr 8px;
  justify-items: center;
  width: 100%;

  /* @media screen and (min-width: 700px) {
        padding-left: 180px;
    } */
  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr calc(45px + 2rem);
    padding: 0.3rem 0.2rem 0 190px;
  }
  @media screen and (min-width: 1000px) {
    padding-right: 1rem;
  }
`;

const Ingress = styled.div`
  max-width: 560px;
  display: none;
  margin: 0.3rem 1rem 0 0;

  h1 {
    font-size: 2.2rem;
  }

  div {
    text-align: center;
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    line-height: 1.3rem;
  }

  @media screen and (min-width: 700px) {
    display: block;
    .svans {
      display: none;
    }
  }
  @media screen and (min-width: 800px) {
    .svans {
      display: inline-block;
    }
    h1 {
      font-size: 3rem;
    }
  }
`;

const Rubrik = styled.img`
  width: 100%;
  margin: 0;
`;

let color = "";
let bgimg = "";
let bgpos = "";
let rubrik = rubrik_hem;

const Header = ({ page }) => {
  switch (page) {
    // case "stage":
    //     title = "Musik på Krogbacken";
    //     subt = "Skön musik i en underbar miljö!";
    //     color = "white";
    //     bgimg = "";
    //     bgpos = "bottom 72% right";
    //     rubrik = rubrik_musik;
    //     break;
    default:
      color = "white";
      bgimg = flygbild;
      bgpos = "bottom center";
      rubrik = rubrik_hem;
  }

  return (
    <>
      <HeaderStyle bg={bgimg} color={color} bgpos={bgpos}>

        <SlideShowHolder page={page}>
          {/* <SpringSlides /> */}
        </SlideShowHolder>

        <Vi />
      </HeaderStyle>
      <SubMenu>
        <Ingress>
          <h1>
            <Rubrik src={rubrik} />
          </h1>

          <div>
            Njut av hemlagad mat, konserter och författarbesök i en idyllisk
            skärgårdsmiljö med utsikt över öar och båtliv! Den gamla
            sjömanskrogen har anor sedan <nobr>1600-talet</nobr>{" "}
            <span className="svans">
              {" "}
              och ligger vackert belägen i gamla Oxelösund
            </span>
            .
          </div>
        </Ingress>
        <Ikoner />
      </SubMenu>
    </>
  );
};

export default Header;
