import styled from "styled-components";

import vi from './../images/vi_medium.jpg';
import Pratbubbla from "./Pratbubbla";

const ViHolder = styled.div`
    grid-row-start: 3;
    grid-column-start: 1;
    margin-left: 1rem;
    margin-top: 15px;
    z-index: 2;
    transition: 0.5s ease-in-out;
    position: relative;

    @media screen and (min-width: 700px) {
        margin-left: 1rem;
        /* margin-top: 0; */
    }
    @media screen and (min-width: 800px) {
        margin-left: 1.5rem;
    }
`;

const ViImg = styled.img`
    width: 110px;
    height: 110px;
    border: 3px solid white;
    border-radius: 50%;
    box-shadow: -4px 1px 8px gray;
    object-fit: cover;

    /* @media screen and (min-width: 700px) {
        width: 140px;
        height: 140px;
    } */
`;

const Vi = () => {
    return (
    <ViHolder>
        <Pratbubbla /> 
        <ViImg width='110' height='110' src={vi} />  
    </ViHolder>       
    )
}

export default Vi;
