import { Link } from "react-router-dom";
import styled from "styled-components";

export const Header = styled.header`
    padding: 0;
    h1 {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        line-height: 1rem;
        align-items: center;
        max-width: 1100px;

        @media screen and (min-width: 600px) {
            padding: 0 2rem 0 1.5rem;
        }

        @media screen and (min-width: 650px) {
            flex-direction: row;
            padding: 0;
            margin: 1rem 0 0.8rem 0;
        }

        img {
            display: block;
            margin: 0;
        }
    }
`;

export const Main = styled.main`
    padding: 0 2rem 1rem 1.5rem;
    max-width: 1100px;

    article {
        p {
            margin-top: 0;
        }
    }

    @media screen and (min-width: 650px) {
        display: flex;
        justify-content: space-between;

        article {
            div {
                column-count: 2;
                column-span: all;
                column-gap: 20px;                
            }
        }
    }
`;

export const Img = styled.img`
    margin: 0 1rem 1rem 0;
    width: 100%;
    height: auto;

    @media screen and (min-width: 650px) {
        float: left;
        width: 300px;
        height: 300px;
    }
    @media screen and (min-width: 650px) {
        width: 100%;
        height: auto;
    }
`;

export const Rubrik1 = styled.img`
    width: 100%;
    @media screen and (min-width: 550px) {
        padding: 0 1rem;
    }
    @media screen and (min-width: 650px) {
        padding: 0;
        width: ${props => props.width};
    }
`;
export const Rubrik2 = styled.img`
    width: ${props => props.width};
    @media screen and (min-width: 550px) {
        padding: 0 1rem;
    }
    @media screen and (min-width: 650px) {
        padding: 0;
        width: ${props => props.width2};
    }
`;

export const Nav = styled.nav`

    ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
    }
`;

export const NavLink = styled(Link)`
    text-decoration: none;
    display: block;
    padding: 0.3rem;
    border: solid 1px #666;
    border-radius: 15px;
    width: 95px;
    text-align: center;
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
`;

