import Navigation from "./../components/Navigation";
import { Main, Header, Img, Rubrik1, Rubrik2 } from "../styles/AboutStyles";

import vi from "./../images/vi_stor.jpg";
import rubrik1 from "./../images/rubrik_about1.png";
import rubrik2 from "./../images/rubrik_about2.png";

import HomeButton from "../components/HomeButton";

const Welcome = () => {
    return (
        <>
            <Navigation page={'welcome'} />
            <Main>
                <article>
                    <Header><h1 alt={"Välkommen till Oxelö Krog i Gamla Oxelösund!"}><Rubrik1 src={rubrik1} width='58%' /><Rubrik2 src={rubrik2} width='73%' width2='42%' /></h1> </Header>
                    <div>
                        <Img src={vi} />
                        <p>
                            Entreprenörerna Thord och Maria Lindé driver det anrika caféet vidare i kulturens anda och gärna tillsammans med en god kopp kaffe! Vi ser fram emot att både nya och gamla besökare ska hitta till Oxelösunds gamla sjömanskrog och få möjlighet att uppleva den vackra och historiska miljön!
                        </p>
                        <p>
                            Vi serverar kaffebröd, kakor och tårtor samt lättare maträtter och vi har fullständiga rättigheter. Vi anordnar även kafferep, middagar och fester för bokade sällskap.
                        </p>
                        <p>
                            Kom och njut av en sommarkväll med bra musik, god mat och den underbara utsikten från krogbacken! På lördagskvällar i juli har haft vi konserter! På tisdagseftermiddagar har vi författare på besök. och vi har även tre konstutställningar inne på krogen. 
                        </p>
                        <p>
                            Traditionsenligt har vi en Bellmandag! I år inträffade den söndagen den 28 juli och det var fri (eller frivillig) entré. Bellmanssång på scenen av Thord Lindé med Fredmans Stråkar.
                        </p>
                        <p>
                            Att sitta på vår uteservering och titta på utsikten är underbart! Men det går utmärkt att komma till oss även när det regnar! Vi har tre fina och trivsamma rum där man kan sitta och känna av historiens närvaro. Vid kyligt väder kan du värma dig vid elden i öppna spisen. Och varför inte när ni är här försöka finna reda på den mytiska blodfläcken?
                        </p>

                        <HomeButton />
                    </div>
                </article>
            </Main>
        </>
    );
}

export default Welcome;