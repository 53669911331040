import React, { useState, useEffect } from "react";

import DropWindow from "./../components/DropWindow";
import dagens from "../lib/menyer";

const Mat = dagens["mat"];

const Meny = () => {
  return (
    <>
      <h2>MENY</h2>
      <Mat />
    </>
  );
};

function Menu() {
  const [dropdown, setDropdown] = useState("");

  function getMenu() {
    setDropdown("menu");
  }

  useEffect(() => {
    getMenu();
  }, []);

  return (
    <DropWindow dropdown={dropdown} setDropdown={setDropdown}>
      <Meny />
    </DropWindow>
  );
}

export default Menu;
