import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import ReactPlayer from 'react-player/youtube';


import getImages from '../lib/getImages';
import Navigation from "../components/Navigation";
import Blad from './../images/blad.jpg';
import Logos from "./../components/Logos";

import artistInfo from "./../lib/artistInfo";
import Pdf from '../Documents/KONSERTMENY.pdf';

const NavHolder = styled.div`
  @media screen and (min-width: 501px) {
    margin-left: 2.%;
    margin-right: 2%;
  }
  @media screen and (min-width: 600px) {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (min-width: 800px) {
    margin-left: 8%;
    margin-right: 8%;
  }
`;

const Main = styled.main`
  margin: 0.5rem auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0;
`;

const Article = styled.article`
  
  width: 100%;
  margin: 0;

  ul {
      list-style: none;
      font-style: italic;
      /* margin-left: 2rem; */
  }

  @media screen and (min-width: 501px) {
    width: 95%;
  }
  @media screen and (min-width: 600px) {
    width: 85%;
  }
  @media screen and (min-width: 800px) {
    width: 80%;
  }
`;

const Datum = styled.div`
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1.5rem 0 0 0;
  line-height: 1.8rem;
  text-align: center;
`;

const Header = styled.header`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 60px;
  grid-template-columns: 1fr;

  img {
    width: 100%;
    grid-column-start: 1;
    grid-row: 1 / 3;
  }
`;

const EvenemangBg = styled.div`
  background-color: #002;
  opacity: 0.7;
  grid-row: 2 / 3;
  grid-column-start: 1;
  z-index: 1;
`;

const Evenemang = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  z-index: 2;
 
    h1 {
      font-family: 'nunito', serif;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2rem;
      text-transform: uppercase;
      color: #eee;
      text-align: center;
    }
`;

const Buy = styled.button`
  display: block;
  padding: 0.5rem 0.8rem 0.4rem 0.8rem;
  border-radius: 25px;
  background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 5px;
  transition: box-shadow .2s;
  margin: 1rem auto 2rem auto;

  :hover {
    cursor: pointer;
    box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  }
  :active {
    box-shadow: rgba(253, 76, 0, 0.9) 0 2px 4px;
  }
`;

const Text = styled.div`
  margin: 0 1rem;
`;

const BasInfo = styled.aside`
  padding: 1.2rem 1rem 1rem 0.8rem;
  border: double #999 3px;
  border-radius: 10px;
  margin: 1.5rem auto;
  max-width: 800px;
  text-align: center;
  font-size: 0.9rem;
  background-image: url(${Blad});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 18%;

  h4 {
    margin: 0 0 0.2rem 0;
  }

  @media screen and (min-width: 520px) {
    margin: 1.5rem auto;
  }
    
`;

const Pris = styled.p`
  margin-top: 2rem;
`

const Authors = styled.div`
  padding: 1.2rem 1rem 1rem 0.8rem;
  border: double #999 3px;
  border-radius: 10px;
  margin: 1.5rem auto;
  max-width: 800px;
  text-align: center;
  font-size: 0.9rem;
  background-image: url(${Blad});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 18%;

  h4 {
    margin: 0 0 0.2rem 0;
  }

  @media screen and (min-width: 520px) {
    margin: 1.5rem auto;
  }
`;

const månad = ['', 'januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];
const veckodag = ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'];

let m;
let d;
let dag;

const Fotograf = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  color: white;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
`;

const Viktigt = styled.div`
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(-33deg);
  font-size: 3em;
  font-weight: bold;
  color: red;
  text-align: center;
  z-index: 99;

  @media screen and (min-width: 520px) {
    top: 320px;
    font-size: 3.5em;
  }

  @media screen and (min-width: 600px) {
    top: 330px;
    font-size: 3.5em;
  }

  @media screen and (min-width: 700px) {
    top: 400px;
    font-size: 3.5em;
  }

  @media screen and (min-width: 800px) {
    top: 450px;
    font-size: 4em;
  }

  @media screen and (min-width: 1000px) {
    top: 530px;
    font-size: 5em;
  }
`;

const ViktigText = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const Medlemskap = styled.div`
  margin: 1rem 2rem;
`;


const Event = () => {

  let { id } = useParams();

  // const [event, setEvent] = useState([]);

  // async function getEvent() {
  //   /*
  //   const response = await fetch('https://www.oxelokrog.se/api/evenemang/read.php');
  //   const data = await response.json();
  //   */
  //   const events = artistInfo.filter(obj => obj.id === Number(id));

  //   setEvent(events);

  // }

  // useEffect(() => {
  //   getEvent();
  // }, []);

  const event = artistInfo.filter(obj => obj.id === Number(id))[0];

  [, m, d] = event['datum'].split("-");
  dag = new Date(event['datum']);

  const Presentation = event['info'];
  const Artist = event['namn'];

  const biljett_id = event['biljett_id'];
  const video = event['video'];
  const videonamn = event['videonamn'];

  const Namn = function () {
    return (
      <h1><Artist /></h1>
    )
  }

  const [purchase, setPurchase] = useState(false);
  function buyTicket() {
    setPurchase(true);
  }
  purchase && window.location.replace('https://secure.tickster.com/' + biljett_id);

  return (
    <>
      <NavHolder>
        <Navigation page={'stage'} />
      </NavHolder>
      <Main>
        <Article>
          <Header>
            <img src={getImages[event['img'] + '.jpg']} alt="event" />
            {
              event['slutsålt'] && <Viktigt>SLUTSÅLT</Viktigt>
            }
            {
              event['inställt'] && <Viktigt>INSTÄLLT</Viktigt>
            }
            <EvenemangBg />

            <Evenemang>
              <Namn />
              {event['foto'] ? <Fotograf>Foto: {event['foto']}</Fotograf> : null}
            </Evenemang>

          </Header>
          <Text>


            <Datum>{event['konst'] && "VERNISSAGE "} {veckodag[dag.getDay()]} {parseInt(d, 10)} {månad[parseInt(m)]} <nobr>{event['tid']}</nobr></Datum>

            {
              event['pris'] !== 0 && event['inställt'] ? <ViktigText>EVENEMANGET ÄR TYVÄRR INSTÄLLT</ViktigText> :
                event['pris'] !== 0 && event['slutsålt'] ? <ViktigText>EVENEMANGET ÄR SLUTSÅLT</ViktigText> :
                  event['pris'] < 2 ? "" : <Buy type='button' onClick={buyTicket}>KÖP BILJETT</Buy>
            }

            <Presentation />

            {
              event['youtube'] && <p><ReactPlayer url={event['youtube']} playbackRate={1} /></p>
            }

            {
              event['youtube2'] && <p><ReactPlayer url={event['youtube2']} playbackRate={1} /></p>
            }

            {
              video && <p>Video: <a href={video} rel="noreferrer" target="_blank">{videonamn}</a></p>
            }

            <Pris>
              {
                event['pris'] === 0 ? "Fri entré för caféets gäster!" : event['pris'] === 1 ? "" : "BILJETTPRIS: " + event['pris'] +
                  " KR. MEDLEMSPRIS: " + Math.floor(event['pris'] - event['pris'] * 0.15) + " KR. Serviceavgift tillkommer."
              }
              <br /><br />


            </Pris>

            {
              event['kväll'] && <p>
                Caféet öppnar för kvällen kl 17:00.<br /><br />
                Mat behöver förbeställas och då gäller vårt speciella <a rel="noreferrer" href={/konsertmat/} target="_blank">KONSERTMENY</a>.

                <br />
                Har man förbeställt mat är man garanterad sittplats vid ett bord.
                I annat fall gäller sittplatser så långt de räcker eller ståplats. Vi behöver din beställning minst fem dagar i förväg.

                <br /><br />
                Drycker och enklare förtäring kommer kunna köpas på plats.<br /><br />
                Vi har fullständiga rättigheter.
              </p>
            }

            {
              event['music'] ? <BasInfo>

                <h4>MUSIK PÅ KROGBACKEN</h4> arrangeras av föreningen <nobr>Kultur på Krogbacken</nobr>.
                <Medlemskap>Medlemskap i föreningen Kultur på Krogbacken kostar 110 kr och kan lösas genom en inbetalning till BG 123-8245. Skicka samtidigt ett mail till kultur@krogbacken.se med namn och gärna telefonnummer.</Medlemskap>

                <Logos />

                <p>Scenen byggdes 2021 med stöd av Leader Sörmlandskusten <nobr>och Oxelösunds Kommun.</nobr></p>
              </BasInfo> :
                event['konst'] ? <p></p> :
                  event['tonkonst'] ? <p></p> :
                    <Authors>
                      <h4>Författar-tisdagar på Krogbacken</h4>
                      <p>
                        Välkomna att träffa och att lyssna på några fantastiska författare på Krogbacken!
                      </p>
                      <p>
                        Under sommaren har vi på tisdagar bjudit in olika författare<br />
                        att berätta om sitt författarskap och sina böcker.
                      </p>
                      <p>Författar-tisdagarna arrangeras av föreningen Kultur på Krogbacken.</p>
                      <Medlemskap>Medlemskap i föreningen Kultur på Krogbacken kostar 110 kr och kan lösas genom en inbetalning till BG 123-8245. Skicka samtidigt ett mail till kultur@krogbacken.se med namn och gärna telefonnummer.</Medlemskap>
                      <p>
                        Varmt välkomna!
                      </p>

                    </Authors>
            }

          </Text>
        </Article>
      </Main>
    </>

  );
}

export default Event;