import { HashLink } from 'react-router-hash-link';
import styled from "styled-components";

const ButtonHolder = styled.div`
    display: flex;
    justify-content: center;
`;

const Button = styled(HashLink)`
    display: block;
    font-size: 0.9rem;
    margin: 0 auto;
    padding: 2px 10px 0 10px;
    border: double #333;
    border-radius: 1rem;
    text-decoration: none;
    box-shadow: -2px 1px 4px gray;

    :hover {
        cursor: pointer;
    }
`;

const ScrollBackButton = () => {

  return (
    <ButtonHolder>
        <Button to="/#top" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
        HEM
        </Button>
    </ButtonHolder> 
    );     
}

export default ScrollBackButton;