import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';

import NavRoutes from "./NavRoutes";
import Gabriola from './fonts/Gabriola.ttf';

import ScrollToTop from "./components/ScrollToTop";

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Gabriola';
    src: url(${Gabriola}) format('truetype');
    font-weight: initial;
    font-style: normal;
    font-display: auto;
  }

  * {
   padding: 0;
   box-sizing: border-box;
   font-size: 100%;
  }

body > * {
   margin: 0;
   background-color: #fff;
   font-family: 'nunito', serif;
   font-weight: 400;
   font-style: normal;
   line-height: 1.2rem;
   font-size: 1rem;
 }

 body {
   margin: 0;
 }

 h1 {
  font-family: 'Gabriola', sans-serif;
  font-weight: normal;
  font-style: normal;
   margin: 0;
   font-size: 2rem;
   line-height: 2.2rem;
   margin-bottom: 0;
 }

h2 {
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  font-size: 1.6rem;
   margin: 0;
   line-height: 1.8rem;
 }

 h3, h4, footer {
   font-weight: initial;
   line-height: 1;
 }

 h3 {
   font-size: 1.3rem;
 }

 h4 {
   font-size: 1.4rem;
 }

 a {
   text-decoration: none;
 }

  a:hover {
    color: #e06630;
  }

`;

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <GlobalStyle />
    <NavRoutes />
  </Router>,
  document.getElementById("root")
);


