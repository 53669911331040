import { useLocation, Outlet } from "react-router-dom";
import styled from "styled-components";

import Stage from "./Stage";
import Images from "./Images";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import Navigation from "./../components/Navigation";

const Container = styled.div`
    display: block;
    max-width: 1100px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: -4px 1px 8px gray;
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 650px) {
        /* flex-direction: column-reverse; */
    }
`;

export default function Home() {
    const path = useLocation().pathname;
    let page = path.split("/")[1];
    let event_id = path.split("/")[3];
    if (!page) { page = "home" }

    return (
        <Container>
            <Top id="top">
                <Navigation page={page} />
                <Header page={page} />
            </Top>
            <Stage event_id={ event_id } page={page} />
            <Images />
            <Outlet />
            <Footer />
        </Container>
    );
}
