import React from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";

import Thumb from "./Thumb";

const CardHolder = styled.div`
  ${(props) => props.chosen && ""};
`;

const CardStyle = styled(animated(Link))`
  background: white;
  text-decoration: none;
  color: #444;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 8;

  position: relative;
  top: 0;
  z-index: 9;

  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
`;

const Card = ({ event_id, id, datum, evenemang, music, img }) => {
  const [{ scale, boxShadow }, api] = useSpring(() => ({
    scale: 1,
    boxShadow: "-2px 3px 6px 0px rgba(0,0,0,0.5)",
    config: {
      mass: 5,
      tension: 350,
      friction: 40,
    },
  }));

  const chosen = id === event_id ? 1 : 0;

  return (
    <CardHolder chosen={chosen}>
      <CardStyle
        style={{ scale, boxShadow }}
        onMouseOver={() =>
          api({ scale: 1.04, boxShadow: "-8px 6px 12px 0px rgba(0,0,0,0.6)" })
        }
        onMouseOut={() =>
          api({ scale: 1, boxShadow: "-4px 3px 6px 0px rgba(0,0,0,0.4)" })
        }
        to={"/event/" + id}
      >
        <Thumb img={img} evenemang={evenemang} datum={datum} />
        {/* <Article beskrivning={beskrivning} /> */}
      </CardStyle>
    </CardHolder>
  );
};

export default Card;
