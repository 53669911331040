import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DropWindow from "./../components/DropWindow";

const UL = styled.ul`
  display: inline-block;
   text-align: left;
   margin-top: 0;
   margin-left: auto;
   margin-right: auto;

   li {
    list-style: disc;
    margin-top: 0.8rem;
   }
`;

const OpenTimes = () => {
  return (
    <>
      <h2>ÖPPETTIDER</h2>
      <p>
        Nu har vi stängt för säsongen och öppnar igen till påsk<br />
      </p>


      {
        /*

        */
      }

      <p>God jul och gott år!</p>
    </>
  );
};

function Open() {
  const [dropdown, setDropdown] = useState("");

  function getOpen() {
    setDropdown("open");
  }

  useEffect(() => {
    getOpen();
  }, []);

  return (
    <DropWindow dropdown={dropdown} setDropdown={setDropdown}>
      <OpenTimes />
    </DropWindow>
  );
}

export default Open;