import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import bibb from "./../images/artister/2023/bibb_bildspel.jpg";
import backenroth from "./../images/artister/2023/hans_backenroth_bildspel.jpg";
import hammar from "./../images/artister/2023/karin_hammar_bildspel.jpg";
import ladies from "./../images/artister/2023/ladies_bildspel.jpg";

const slides = [
  { namn1: "Eric Bibb", namn2: "", img: bibb, datum: "1 juli", left: -24 },
  {
    namn1: "Ladies got the Blues",
    namn2: "",
    img: ladies,
    datum: "8 juli",
    left: -7,
  },
  {
    namn1: "Karin Hammar Fab 4",
    namn2: "",
    img: hammar,
    datum: "15 juli",
    left: -24,
  },
  {
    namn1: "Hans Backenroth Q",
    namn2: "",
    img: backenroth,
    datum: "29 juli",
    left: -7,
  },
];

const Slide = styled(animated.div)`
  position: relative;
  height: 180px;
  width: 180px;
  margin-top: 7px;

  @media screen and (min-width: 800px) {
    height: 210px;
    width: 210px;
  }
`;

const Info = styled.div`
  position: absolute;
  z-index: 200;
  bottom: 0;
  width: 180px;
  color: white;
  text-align: center;
  text-shadow: 0 0 3px black;
  line-height: 1.2rem;
  padding-top: 5px;
  padding-bottom: 7px;
  border-radius: 5px;

  @media screen and (min-width: 800px) {
    width: 210px;
  }
`;

const Image = styled.div`
  margin: 0;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-mask-image: radial-gradient(black, black, transparent);
  mask-image: radial-gradient(black, black, transparent);

  @media screen and (min-width: 800px) {
    height: 210px;
    width: 210px;
  }

  img {
    /* margin-left: ${(props) => props.left + 5}px; */
    opacity: 0.86;
    width: 180px;
    height: 180px;

    @media screen and (min-width: 800px) {
      height: 210px;
      width: 210px;
    }
  }
`;

const SlideContent = ({ img, datum, namn1, namn2, left }) => {
  return (
    <>
      <Image left={left}>
        <img src={img} alt="" />
      </Image>
      <Info>
        {namn1}
        <br />
        {namn2 && <div>{namn2}</div>}
        {datum}
      </Info>
    </>
  );
};

const SpringSlides = () => {
  const [number, setNumber] = useState(0);

  const confIn = { duration: 4000, mass: 5, tension: 30, friction: 40 };
  const confOut = { duration: 3000, mass: 5, tension: 35, friction: 40 };

  const styles = useSpring({
    to: async (next, cancel) => {
      await next({ opacity: 0.8, clipPath: "circle(63%)", config: confIn });
      await next({
        opacity: 1,
        clipPath: "circle(63%)",
        config: { duration: 1000 },
      });
      await next({
        opacity: 0.8,
        clipPath: "circle(63%)",
        config: { duration: 1000 },
      });
      await next({ opacity: 0, clipPath: "circle(1%)", config: confOut });
      await next({ delay: 3000 });
    },
    from: { opacity: 0, clipPath: "circle(1%)" },
    onRest: () => {
      /* Avgör hur många bilder som visas 0=2 bilder, 1=3 bilder etc. */
      if (number > 2) {
        setNumber(0);
      } else {
        setNumber(number + 1);
      }
    },
  });

  /* <CLEANUP> Runs on unmpunt and works! */
  const [, setState] = useState({});
  useEffect(() => {
    myFunction();
    return () => {
      setState({}); // This worked for me
    };
  }, []);
  const myFunction = () => {
    setState({
      name: "John",
      surname: "Doe",
    });
  };
  /* </CLEANUP> */

  return (
    <>
      <Slide style={styles}>
        <SlideContent
          img={slides[number]["img"]}
          datum={slides[number]["datum"]}
          namn1={slides[number]["namn1"]}
          namn2={slides[number]["namn2"]}
          left={slides[number]["left"]}
        />
      </Slide>
    </>
  );
};

export default SpringSlides;
