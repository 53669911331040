import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from "styled-components";

const Button = styled.button`
    display: block;
    font-size: 0.9rem;
    margin: 0 auto;
    padding: 0 10px;
    border-radius: 0.6rem;

    :hover {
        cursor: pointer;
    }
`;

const HomeButton = () => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/', {replace: true}), [navigate]);

  return (
    <Button type="button" onClick={handleOnClick}>
      HEM
    </Button>
  );
}

export default HomeButton;