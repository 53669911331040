import styled from "styled-components";

const Toggle = styled.input.attrs({ type: "checkbox", id: "navator" })`
    display: none;

    ~ label {
        position: relative;
        padding: 0.25rem;
        width: 2rem;
        height: 2rem;
    }

    ~ label > span,
    ~ label > span::before,
    ~ label > span::after {
        content: "";
        display: block;
        position: absolute;
        top: 1.5rem;
        height: 0.18rem;
        width: 2rem;
        opacity: 1;
        background: #000;
        transition: 0.14s ease-in-out;
    }

    ~ label > span::before {
        top: -0.6rem;
    }

    ~ label > span::after {
        top: 0.6rem;
    }

    :checked ~ label > span {
        width: 10px;
        background: rgba(18, 18, 18, 0);
    }

    ~ label:hover {
        cursor: pointer;
    }

    &:checked ~ label > span::before {
        transform: rotateZ(45deg);
        top: 0;
    }

    &:checked ~ label > span::after {
        transform: rotateZ(-45deg);
        top: 0;
    }

    &:checked ~ nav {
        left: 0;
    }

    @media screen and (min-width: 700px) {
        ~ label {
            display: none;
        }    
    }
`;

const NavToggle = () => {
    return <>
        <Toggle/>
        <label htmlFor="navator" className="navToggleLabel">
            <span></span>
        </label>
    </>
}

export default NavToggle;