import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Event from "./pages/Event";
import About from "./pages/About";
import History from "./pages/History";
import Welcome from "./pages/Welcome";
import Formular from "./pages/Formular";
import Visit from "./pages/Visit";
import Images from "./pages/Images";
import Stage from "./pages/Stage";
import Menu from "./pages/Menu";
import Aktuellt from "./pages/Aktuellt";
import Open from "./pages/Open";
import Contact from "./pages/Contact";
import Sponsorer from "./pages/Sponsorer";
import Whoops404 from "./pages/Whoops404";

export default function NavRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="stage" element={<Stage />} />
        <Route path="menu" element={<Menu />} />
        <Route path="aktuellt" element={<Aktuellt />} />
        <Route path="open" element={<Open />} />
        <Route path="contact" element={<Contact />} />
      </Route>

      <Route exact path="about" element={<About />}>
        <Route path="welcome" element={<Welcome />} />

        <Route path="visit" element={<Visit />} />
        <Route path="history" element={<History />} />
      </Route>

      <Route exact path="feedback" element={<Formular />}>
      </Route>

      <Route path="event">
        <Route path=":id" element={<Event />} />
      </Route>

      <Route path="bilder" element={<Images />} />

      <Route path="sponsorer" element={<Sponsorer />} />

      <Route path="*" element={<Whoops404 />} />
    </Routes>
  );
}
